import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container-box-style privacyPage">
      <h2 className="my-5 d-flex justify-content-center">Privacy Policy</h2>
      <p>
        This privacy notice for Vitrine.live (<strong>"we," "us,"</strong> or{" "}
        <strong>"our"</strong>), describes how and why we might collect, store,
        use, and/or share (<strong>"process"</strong>) your information when you
        use our services (<strong>"Services"</strong>).
        <br />
        <strong>Questions or concerns?</strong> Reading this privacy notice will
        help you understand your privacy rights and choices. If you do not agree
        with our policies and practices, please do not use our Services.
      </p>
      <ol>
        <li>WHAT INFORMATION DO WE COLLECT?</li>
        <p>
          <strong>Personal information you disclose to us</strong>
          <br />
          <strong>In Short:</strong> We collect personal information that you
          provide to us. We collect personal information that you voluntarily
          provide to us when you register on the Services, express an interest
          in obtaining information about us or our products and Services, when
          you participate in activities on the Services, or otherwise when you
          contact us.
          <br />
          <strong>Sensitive Information.</strong> We do not process sensitive
          information. All personal information that you provide to us must be
          true, complete, and accurate, and you must notify us of any changes to
          such personal information.
          <br />
          <br />
          <strong>Information automatically collected</strong>
          <br />
          <strong>In Short:</strong> Some information — such as your Internet
          Protocol (IP) address and/or browser and device characteristics — is
          collected automatically when you visit our Services.. We automatically
          collect certain information when you visit, use, or navigate the
          Services. This information does not reveal your specific identity
          (like your name or contact information) but may include device and
          usage information, such as your IP address, browser and device
          characteristics, operating system, language preferences, referring
          URLs, device name, country, location, information about how and when
          you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes. Like many businesses, we also collect information through
          cookies and similar technologies.
        </p>
        <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
        <p>
          <strong>In Short: </strong>We process your information to provide,
          improve, and administer our Services, communicate with you, for
          security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent.
          <br />
          <br />
          <strong>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </strong>
        </p>
        <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
        <strong>In Short: </strong>We may share information in specific
        situations described in this section and/or with the following third
        parties.
        <br />
        We may need to share your personal information in the following
        situations:
        <br />
        <ul>
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
          <li>
            <strong>Affiliates.</strong> We may share your information with our
            affiliates, in which case we will require those affiliates to honour
            this privacy notice. Affiliates include our parent company and any
            subsidiaries, joint venture partners, or other companies that we
            control or that are under common control with us.
          </li>
          <li>
            <strong>Business Partners.</strong> We may share your information
            with our business partners to offer you certain products, services,
            or promotions.
          </li>
        </ul>
        <br />
        <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
        <p>
          <strong>In Short:</strong> We may use cookies and other tracking
          technologies to collect and store your information. We may use cookies
          and similar tracking technologies (like web beacons and pixels) to
          access or store information. Specific information about how we use
          such technologies and how you can refuse certain cookies is set out in
          our Cookie Notice.
        </p>
        <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
        <p>
          <strong>In Short:</strong>If you choose to register or log in to our
          Services using a social media account, we may have access to certain
          information about you.
          <br />
          <br />
          Our Services offer you the ability to register and log in using your
          third-party social media account details.. We will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
          <br />
          <br />
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services.
          <br />
          <br />
          Please note that we do not control, and are not responsible for, other
          uses of your personal information by your third-party social media
          provider. We recommend that you review their privacy notice to
          understand how they collect, use, and share your personal information,
          and how you can set your privacy preferences on their sites and apps.
        </p>
        <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
        <p>
          <strong>In Short:</strong> We may transfer, store, and process your
          information in countries other than your own.
          <br />
          <br />
          Our servers are located in the Netherlands. If you are accessing our
          Services from outside, please be aware that your information may be
          transferred to, stored, and processed by us in our facilities and by
          those third parties with whom we may share your personal information.
          <br />
          <br />
          If you are a resident in the European Economic Area (EEA), United
          Kingdom (UK), or Switzerland, then these countries may not necessarily
          have data protection laws or other similar laws as comprehensive as
          those in your country. However, we will take all necessary measures to
          protect your personal information in accordance with this privacy
          notice and applicable law.
        </p>
        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
        <p>
          <strong>In Short:</strong>We keep your information for as long as
          necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
          <br />
          <br />
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements).
          <br />
          <br />
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
        <p>
          <strong>In Short: </strong>We do not knowingly collect data from
          children under 18 years of age.
          <br />
          <br />
          We do not knowingly solicit data from children under 18 years of age.
          By Using the Services, you represent that you are at least 18 or that
          you are the parent or guardian of such a minor and consent to such
          minor dependent’s use of the Services. If we learn that personal
          information from users less than 18 years of agehas been collected, we
          will deactivate the account and take reasonable measures to promptly
          delete such data from our records. If you become aware of any data we
          may have collected from children under age 18, please contact us at
          support@vitrine.live.
        </p>
        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
        <p>
          <strong>In Short: </strong>
          You may review, change, or terminate your account at any time.
          <br />
          <br />
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us at support@vitrine.live.
          <br />
          <br />
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
          <br />
          <br />
          Account Information
          <br />
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
          <br />
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
        <p>
          <strong>In Short: </strong>Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
          <br />
          <br />
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
        <p>
          If you have questions or comments about this notice, you may contact
          us at: <a href="mailto:support@vitrine.live">support@vitrine.live</a>.
        </p>
        <li>
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </li>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please email{" "}
          <a href="mailto:support@vitrine.live">support@vitrine.live</a>.
        </p>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
