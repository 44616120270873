import {
  CreatorCLogo,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  MailIcon,
  XIcon,
} from "../components/Icons";

// Images Import
import AgencyHubLogo from "../assets/landing-page/agency-hub-logo.png";
import CreatorCatalogueLogo from "../assets/landing-page/creator-catalogue-logo.png";
import ExchangeLogo from "../assets/landing-page/exchange-logo.png";
import { Link } from "react-router-dom";
import AnimatedTile from "../components/AnimatedTile";


// import React, { useState, useEffect } from "react";

// const useIsPhone = () => {
//   const [isPhone, setIsPhone] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsPhone(window.innerWidth <= 768); // Adjust breakpoint as needed
//     };

//     handleResize(); // Initial check
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return isPhone;
// };

const HomePage = () => {
  // const isPhone = useIsPhone();

  // Footer Functions - START
  const navigateToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/vitrinelive/", "_blank");
  };

  const navigateToX = () => {
    window.open(
      "https://x.com/vitrine_live?s=21&t=RIJgSPeiVhMOIy5-Xqd9ww/",
      "_blank"
    );
  };

  const navigateToInstagram = () => {
    window.open(
      "https://instagram.com/vitrinelive?igshid=MzMyNGUyNmU2YQ==/",
      "_blank"
    );
  };
  // Footer Functions - END

  return (
    <div className="overflow-y-auto overflow-x-hidden !font-bricolage bg-light-blue">
      {/* Nav - Start */}
      <div className="flex flex-row px-8 md:px-16 py-5">
        <div className="flex flex-row w-4/6 items-end">
          <CreatorCLogo className="w-32 h-11" />
        </div>
        <div className="flex flex-row gap-4 items-center justify-end w-2/6 text-sm md:text-base">
          <Link to="/">
            <span className=" font-semibold text-primary ">Home</span>
          </Link>
          <Link to="/about">
            <span className="font-semibold text-primary ">About Us</span>
          </Link>
        </div>
      </div>
      {/* Nav - End */}
      {/* Home - Start */}
      <div className="flex flex-col overflow-x-hidden overflow-y-hidden py-10 md:py-20">
        <h2 className="!font-bold text-[48px] md:text-[80px] leading-[48px] md:leading-[80px] bg-gradient-text bg-clip-text text-transparent text-center">
          Paving clear paths
          <br />
          for content creators
        </h2>
        <div className="flex flex-col md:flex-row gap-4 py-10 px-8 md:py-20 md:px-16">
          <AnimatedTile
            title="Creator Catalogue"
            subtitle="Connect, Collaborate and Control"
            image={CreatorCatalogueLogo}
            buttonText="Explore Catalogue"
            stripTitle="Our solution to Creator Collaborations"
            stripSubtitle="Coming Q4 ’24"
            onClick={() => {
              window.location.href = "https://catalogue.vitrine.live";
            }}
          />
          <AnimatedTile
            title="Exchange"
            subtitle="Trade royalties and earn with your favorite creators"
            image={ExchangeLogo}
            buttonText="Start Investing"
            stripTitle="Our solution to Creator Financing"
            stripSubtitle="In Development"
            onClick={() => {}}
          />
          <AnimatedTile
            title="Agency Hub"
            subtitle="Manage, Scale, Collaborate for Marketing Agencies"
            image={AgencyHubLogo}
            buttonText="Get Started with Agencies"
            stripTitle="Our solution for Marketing Agencies"
            stripSubtitle="Coming Q1 ’25"
            onClick={() => {}}
          />
        </div>
      </div>
      {/* Home - End */}
      {/* Footer - Start */}
      <div className="flex flex-col relative">
        <div class="flex flex-col w-full justify-center px-8 md:px-16 md:pt-6 pb-24 gap-4 z-20 mt-32">
          <div className="flex flex-col md:flex-row md:justify-between justify-center">
            <CreatorCLogo className="w-32 h-11 mb-8 md:mb-0 mx-auto md:mx-0" />
            <div className="flex flex-row gap-6 justify-center md:justify-end">
              <MailIcon className="cursor-pointer" />
              <FacebookIcon className="cursor-pointer" />
              <XIcon onClick={navigateToX} className="cursor-pointer" />
              <InstagramIcon
                onClick={navigateToInstagram}
                className="cursor-pointer"
              />
              <LinkedInIcon
                onClick={navigateToLinkedIn}
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <p className="px-8 md:px-0 pb-6 md:pb-0 text-center md:text-left">Copyright © 2023.All Rights Reserved By Vitrine</p>
            <div className="flex flex-row gap-6 justify-between md:justify-end">
              <Link className="" to="/terms-and-conditions">
                Terms and Conditions
              </Link>
              <Link className="" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Footer - End */}
    </div>
  );
};

export default HomePage;
