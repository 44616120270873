import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import HomePage from "./pages/Home";


function App() {
  return (
    <Router>
      <Routes>
        {/* Un-Authenticated Routes - Start */}
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />

        {/* Un-Authenticated Routes - End */}

        {/* Path for Adding a redirect to show popup */}
        <Route
          path="/signup/brand"
          element={<Navigate to="/?signup=brand" />}
        />
        <Route
          path="/signup/creator"
          element={<Navigate to="/?signup=creator" />}
        />

        {/* Redirects all the routes that are not defined to home page. */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
