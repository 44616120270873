import React from "react";
import { motion } from "framer-motion";

const ContinuousStrip = (props) => {
  return (
    <div className="relative overflow-hidden w-full mt-4">
      <motion.div
        className="flex space-x-8 items-center" 
        animate={{ x: ["0%", "-100%"] }}
        transition={{
          duration: 50,
          repeat: Infinity,
          ease: "linear",
        }}
      >
        {/* First Set of Elements */}
        <StripElement heading={props.heading} subtitle={props.subtitle} />
        <StripElement heading={props.heading} subtitle={props.subtitle} />
        <StripElement heading={props.heading} subtitle={props.subtitle} />

        {/* Second Set of Elements (To ensure seamless looping) */}
        <StripElement heading={props.heading} subtitle={props.subtitle} />
        <StripElement heading={props.heading} subtitle={props.subtitle} />
        <StripElement heading={props.heading} subtitle={props.subtitle} />
      </motion.div>
    </div>
  );
};

const StripElement = (props) => (
  <div className="flex items-center space-x-4 whitespace-nowrap">
    {/* Main Heading */}
    <span className="text-black text-base font-semibold">
      {props.heading}
    </span>
    {/* Green Box */}
    <div className="w-4 h-4 bg-primary rounded"></div>
    {/* Subheading */}
    <span className="text-black font-semibold text-base">
      {props.subtitle}
    </span>
    {/* Green Box */}
    <div className="w-4 h-4 bg-primary rounded"></div>
  </div>
);

export default ContinuousStrip;
