import React from "react";

const TermsConditions = () => {
  return (
    <div className="container-box-style termsPage">
      <h2 className="text-center mb-5">Terms & Conditions</h2>

      <p>
        Welcome to Vitrine, an ecosystem in the creator economy dedicated to
        empowering content creators, facilitating collaborations between
        creators and brands, and fostering inclusive opportunities for all
        ecosystem participants including brands, creators, agencies and fans.
      </p>

      <p>
        You can contact us by email at support@vitrine.live or through help on
        website.
        <br />
        IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
        EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
        USE IMMEDIATELY.
        <br />
        The Services are intended for users who are at least 18 years old.
        Persons under the age of 18 are not permitted to use or register for the
        Services.
      </p>

      <ol>
        <li>OUR SERVICES</li>
        <p>
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
        </p>
        <li>USER REPRESENTATIONS</li>
        <p>By using the Services, you represent and warrant that:</p>
        <ul>
          <li>
            all registration information you submit will be true, accurate,
            current, and complete;
          </li>
          <li>
            you will maintain the accuracy of such information and promptly
            update such registration information as necessary;
          </li>
          <li>
            you have the legal capacity and you agree to comply with these
            LegalTerms;
          </li>
          <li>you are not a minor in the jurisdiction in which you reside;</li>
          <li>
            you will not access the Services through automated or non-human
            means, whether through a bot, script or otherwise;
          </li>
          <li>
            you will not use the Services for any illegal or unauthorized
            purpose;
          </li>
          <li>
            your use of the Services will not violate any applicable law or
            regulation.
          </li>
        </ul>
        <br />
        <li>ACCOUNT CREATION, VERIFICATION, AND TERMINATION</li>
        <p>
          Vitrine utilizes Meta API to verify all accounts created on our
          platform. We rely on the accuracy of the data provided by Meta API for
          account verification. Any discrepancies or inaccuracies in the data
          provided by Meta API are not the responsibility of Vitrine.
          <br />
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Services
          (or any portion thereof).
          <br />
          To maintain an active and engaged community, accounts of brands or
          creators that remain inactive, including no login activity for a
          period of three months, will be automatically deactivated by Vitrine.
          This measure ensures that all accounts on the platform are active and
          prevents wasted time for both brands and creators.
        </p>
        <li>COLLABORATIONS AND PAYMENT</li>
        <p>
          Brands and content creators engage in collaborations facilitated by
          Vitrine. Brands can browse content creators' profiles and initiate
          collaboration requests free of charge.
          <br />
          Likewise, content creators explore collaboration opportunities through
          brand campaigns or receive direct messages from interested brands at
          no cost.
          <br />
          Upon reaching an agreement, the brand and creator negotiate the terms
          of the collaboration, including compensation for services rendered.
          Once the creator accepts the offer, Vitrine holds the agreed-upon
          payment in escrow until the collaboration is successfully completed.
          <br />
          Following the brand's instructions, the creator produces the
          agreed-upon content, which is then submitted to the brand for
          approval.
          <br />
          Upon approval by the brand, the creator receives the payment held in
          escrow by Vitrine. In the event that the brand rejects the content,
          Vitrine will review the communication, agreements, and content
          produced to determine any potential misuse of authority. Complaints
          from both brands and creators regarding collaborations will be
          addressed by Vitrine on a case-by-case basis.
          <br />
          <br />
          While Vitrine does not charge fees for its services, brands are
          responsible for paying a small transaction fee to our payment
          facilitator, which is unrelated to Vitrine.
          <br />
          <br />
          You agree to provide current, complete, and accurate purchase and
          account information for all purchases made via the Services. You
          further agree to promptly update account and payment information,
          including email address, payment method, and payment card expiration
          date, so that we can complete your transactions and contact you as
          needed.
          <br />
          <br />
          You agree to pay all charges at the prices then in effect for your
          purchases, and you authorize us to charge your chosen payment provider
          for any such amounts upon placing your order. We reserve the right to
          correct any errors or mistakes in pricing, even if we have already
          requested or received payment.
        </p>
        <li>CANCELATION</li>
        <p>
          You can terminate and delete your account at any time, by going to
          your profile settings and selecting “Delete Account”.
        </p>
        <li>PROHIBITED ACTIVITIES</li>
        <p>As a user of the Services, you agree not to:</p>
        <ul>
          <li>
            Systematically retrieve data or other content from the Services to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Services, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of the Services and/or the Content contained therein.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Services, including harm to brands using your audience.
          </li>
          <li>
            Use any information obtained from the Services in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            Use the Services in a manner inconsistent with any applicable laws
            or regulations.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses,or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Services or modifies, impairs, disrupts, alters, or interferes with
            the use, features, functions, operation, or maintenance of the
            Services.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Services to you.
          </li>
          <li>Copy or adapt the Services' software.</li>
          <li>
            Make any unauthorized use of the Services, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretences.
          </li>
          <li>Use the Services as part of any effort to compete with us.</li>
          <li>
            You may not use our platform in any way that breaches any applicable
            local, national, or international law or regulation.
          </li>
          <li>
            You may not engage in unlawful or fraudulent activities or use the
            platform for any unlawful or fraudulent purpose.
          </li>
          <li>
            You may not use the platform to harm or attempt to harm minors,
            bully, insult, intimidate, or humiliate any person.
          </li>
          <li>
            You may not transmit any unsolicited or unauthorized advertising or
            promotional material, or any other form of similar solicitation
            (spam).
          </li>
          <li>
            You must not access without authority, interfere with, damage, or
            disrupt any part of our platform or any equipment, network, or
            software used in its provision.
          </li>
        </ul>
        <br />
        <li>COMMUNICATION AND CONDUCT</li>
        <p>
          Vitrine expects all users to engage in respectful and professional
          conduct while using the platform. Any form of harassment, abusive
          language, or inappropriate behavior is strictly prohibited. Vitrine
          reserves the right to take appropriate action, including account
          suspension or termination, in cases of misconduct.
          <br />
          On our platform, brands and creators are independent entities not
          affiliated with Vitrine. Vitrine serves as a facilitator for
          connection and collaboration processes but does not dictate the
          content that creators produce or require brands to produce specific
          content.
        </p>
        <li>USER CONTRIBUTION</li>
        <p>
          Users may not utilize our platform in any manner that breaches
          applicable local, national, or international laws or regulations. This
          includes, but is not limited to, unlawful or fraudulent activities,
          harming minors, bullying, or sending unsolicited advertising or
          promotional material.
          <br />
          Contributions, including but not limited to text, images, and videos,
          must adhere to our content standards. This includes refraining from
          defamatory, obscene, offensive, or discriminatory content, promoting
          illegal activities, or infringing on the rights of others.
          <br />
          Users' Contributions cannot be false, inaccurate, or misleading.
        </p>
        <li>OWNERSHIP AND USAGE OF CONTENT</li>
        <p>
          By accepting collaborations with brands, creators agree to allow
          brands to use the content produced for promotional purposes. Content
          submitted through Vitrine Chat may be used by brands in accordance
          with the terms of collaboration.
        </p>
        <li>TERM AND TERMINATION</li>
        <p>
          These Legal Terms shall remain in full force and effect while you use
          the Services.WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
          TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT
          OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
          SOLE DISCRETION.
          <br />
          <br />
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, , including without limitation pursuing
          civil, criminal, and injunctive redress.
        </p>
        <li>MODIFICATIONS AND INTERRUPTIONS</li>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Services at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Services. We will not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the
          Services.
          <br />
          <br />
          We cannot guarantee the Services will be available at all times. We
          may experience hardware, software, or other problems or need to
          perform maintenance related to the Services, resulting in
          interruptions, delays, or errors. We reserve the right to change,
          revise, update, suspend, discontinue, or otherwise modify the Services
          at any time or for any reason without notice to you. You agree that we
          have no liability whatsoever for any loss, damage, or inconvenience
          caused by your inability to access or use the Services during any
          downtime or discontinuance of the Services. Nothing in these Legal
          Terms will be construed to obligate us to maintain and support the
          Services or to supply any corrections, updates, or releases in
          connection therewith.
        </p>
        <li>CORRECTIONS</li>
        <p>
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.
        </p>
        <li>DISCLAIMER</li>
        <p>
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-
          INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
          ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL
          ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
          USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
          SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
          FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
          THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
          OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
          ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
          TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
          WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
          OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
          APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
          NOT BE A PARTY TO OR INANY WAY BE RESPONSIBLE FOR MONITORING ANY
          TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
          SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
          MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
          EXERCISE CAUTION WHERE APPROPRIATE.
        </p>
        <li>LIMITATIONS OF LIABILITY</li>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
          OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER
          OF THE AMOUNT PAID, IF ANY, BY YOU TO US OR . CERTAIN US STATE LAWS
          AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
          OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
          TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT
          APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        <li>INDEMNIFICATION</li>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the Services; (3) breach of these Legal
          Terms; (4) any breach of your representations and warranties set forth
          in these Legal Terms; (5) your violation of the rights of a third
          party, including but not limited to intellectual property rights; or
          (6) any overt harmful act toward any other user of the Services with
          whom you connected via the Services. Notwithstanding the foregoing, we
          reserve the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us,
          and you agree to cooperate, at your expense, with our defense of such
          claims. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aware of it.
        </p>
        <li>USER DATA</li>
        <p>
          We will maintain certain data that you transmit to the Services for
          the purpose of managing the performance of the Services, as well as
          data relating to your use of the Services. Although we perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          the Services. You agree that we shall have no liability to you for any
          loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such
          data.
        </p>
      </ol>
    </div>
  );
};

export default TermsConditions;
